import { css } from '@emotion/react';
import { Avatar } from '@mantine/core';
import { Fragment } from 'react';
import { proxy, ref } from 'valtio';
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { FuiModals } from '~/pages/heineken_template/components/FuiModals';
import { component } from '~/utils/component';
export class FuiUserAvatar {
    store;
    constructor() {
        this.store = proxy({
            modal: new FuiModals(['細節']),
        });
    }
    Avatar = ref(component(props => {
        const meFirebaseState = useMeStore(state => state.meFirebaseState);
        return (<Avatar css={css `
              border-radius: 50px;
            `} className={props.className} alt={meFirebaseState?.name ?? ''} src={meFirebaseState?.avatarUrl ?? undefined} onClick={() => {
                props.onClick?.();
            }}/>);
    }, {
        displayName: '使用者頭像展示',
    }));
    AvatarModal = ref(component(props => {
        return (<Fragment>
            <this.Avatar css={css `
                cursor: pointer;
              `} onClick={() => {
                this.store.modal.open('細節');
            }}/>
            <this.store.modal.WithModalContent for='細節' cssset={css([
                css `
                  ${fill_vertical_all_center};
                  width: 336px;
                  padding: 0px;
                  overflow-y: hidden;
                  overflow-x: hidden;
                  .mantine-1q36a81 {
                    padding: 0px;
                  }
                `,
                props.cssset,
            ])}>
              {props.children}
            </this.store.modal.WithModalContent>
          </Fragment>);
    }, {
        displayName: '使用者頭像按鈕',
    }));
}
